export const namespaced = true
import store from '@/store'
import router from '@/router'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        authuser :'',
        authlocation : '',
        isAuth:'',
		returnvalue:'',
        userip:'',
        clientlocation:'',
        clientdevice:'',
    },
    
    getters : {
        authuser: (state )=> state.authuser,
        authlocation: (state )=> state.authlocation,
		returnvalue: (state )=> state.returnvalue,
        isAuth: state => !!state.authuser,
        userip: (state )=> state.userip,
        clientlocation:(state )=> state.clientlocation,
        clientdevice:(state )=> state.clientdevice,
       
    },


    mutations : { 
        setauthuser :(state,authuser ) => (state.authuser = authuser), 
        setauthlocation :(state,authlocation ) => (state.authlocation = authlocation), 
        setisAuth :(state,isAuth ) => (state.isAuth = isAuth), 
        setreturnvalue :(state,returnvalue ) => (state.returnvalue = returnvalue),
        setuserip :(state,userip ) => (state.userip = userip),
        setclientlocation :(state,clientlocation ) => (state.clientlocation = clientlocation),
        setclientdevice :(state,clientdevice ) => (state.clientdevice = clientdevice),
    },

    actions : {


        getuseripaddress({ commit }){
            fetch('https://api.ipify.org?format=json')
                .then(x => x.json())
                .then(({ ip }) => {
                    commit('setuserip', ip)
                });
        },

        getuserlocation({ commit, getters }){
            console.log(getters.userip)
            const url = "https://ipapi.co/"+ getters.userip +"/json/";
            const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                a: 10,
                b: 20,
            }),
            };
            fetch(url, options)
            .then((response) => response.json())
            .then((data) => {
                commit('setclientlocation', data)
            });
        },


        async register_student( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'register_student')
            return new Promise((resolve, reject) => {
            axios.post("/student/register_student", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role = 'teacher'){
                        this.$router.push({name:'teachers_page'})
                    }else{
                        this.$router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async login_student( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'login_student')
            return new Promise((resolve, reject) => {
            axios.post("/student/login_student", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role == 'teacher'){
                        console.log('teacher')
                        router.push({name:'teachers_page'})
                    }else{
                        router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        async forgot_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/student/forgot_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async reset_password( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/student/reset_password", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async request_otp( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'request_otp')
            return new Promise((resolve, reject) => {
            axios.post("/student/requestotp", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async verify_code( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'verify_code')
            return new Promise((resolve, reject) => {
            axios.post("/student/verify_code", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role= 'teacher'){
                        this.$router.push({name:'teachers_page'})
                    }else{
                        this.$router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async getauthuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'loginuser')
            return new Promise((resolve, reject) => {
            axios.post("/auth/authuser", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role= 'teacher'){
                        this.$router.push({name:'teachers_page'})
                    }else{
                        this.$router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async logoutuser( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'logoutuser')
            return new Promise((resolve, reject) => {
            axios.post("/student/logout", data)
            .then(response => {	
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                toast.success(response.data.msg)
                resolve(response)

            })
            .catch(error=> {
                commit('setloader', false)
                localStorage.removeItem('educanstudenttoken'+ + store.getters.authuser.id)
                commit('setauthuser', '')
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_user_account( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_account')
            return new Promise((resolve, reject) => {
            axios.post("/student/updateaccount", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role= 'teacher'){
                        router.push({name:'teachers_page'})
                    }else{
                        router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async updateprofileimage( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'updateprofileimage')
            return new Promise((resolve, reject) => {
            axios.post("/student/updateprofilepic", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role= 'teacher'){
                        this.$router.push({name:'teachers_page'})
                    }else{
                        this.$router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async changepassword( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'changepassword')
            return new Promise((resolve, reject) => {
            axios.post("/student/updatepassword", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        resolve(response)
                    }else if(response.data.res.user.role= 'teacher'){
                        this.$router.push({name:'teachers_page'})
                    }else{
                        this.$router.push({name:'errorpage'})
                    }
                } 
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async deleteuseraccount( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'deleteuseraccount')
            return new Promise((resolve, reject) => {
            axios.post("/student/deleteaccount", data)
            .then(response => {	
                commit('setloader', false)
                localStorage.clear()
                commit('setauthuser', '')
                toast.success(response.data.msg)
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async confirm_google_account( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'confirm_user_account')
            return new Promise((resolve, reject) => {
            axios.post("/student/confirm_google_account", data)
            .then(response => {	
                if(response.data.res != false){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        toast.success(response.data.msg)
                        if(response.data.res.user.gradecurriculum_id == null){
                            router.push({name:'complete_profile'})
                          }else if(response.data.res.user.phone_verified_at == null){
                            router.push({name:'request_verify_account'})
                          }else{
                            router.push({name:'welcome'})
                          }
                    }else if(response.data.res.user.role= 'teacher'){
                        router.push({name:'teachers_page'})
                    }else{
                        router.push({name:'errorpage'})
                    }
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async create_account_student( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_account_student')
            return new Promise((resolve, reject) => {
            axios.post("/student/create_account_student", data)
            .then(response => {	
                if(response.data.res){
                    if(response.data.res.user.role == 'student'){
                        localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                        commit('setauthuser', response.data.res.user)
                        router.push({name:'welcome'})
                        toast.success(response.data.msg)

                    }else if(response.data.res.user.role= 'teacher'){
                        router.push({name:'teachers_page'})
                    }else{
                        router.push({name:'errorpage'})
                    }
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async renew_account_membership( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'renew_account_membership')
            return new Promise((resolve, reject) => {
            axios.post("/student/membership", data)
            .then(response => {	
                if(response.data.res){
                    localStorage.setItem('educanstudenttoken'+ response.data.res.user.id, response.data.res.authtoken.accessToken)
                    commit('setauthuser', response.data.res.user)
                    toast.success(response.data.msg)
                }
                resolve(response)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            // toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                      
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    },

    
}













