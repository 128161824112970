export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        orders :'', 
        order :'', 
    },
    
    getters : {
        orders: (state )=> state.orders,
        order: (state )=> state.order,
    },


    mutations : { 
        setorders :(state,orders ) => (state.orders = orders), 
        setorder :(state,order ) => (state.order = order), 
    },

    actions : {

        async create_order( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_order')
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                toast.success("order Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_orders( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_orders')
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/myorders?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_order( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_order' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorder', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async cancel_order( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'cancel_order' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/orders/cancel/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setorders', response.data.res)
                toast.success("order Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}