export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        papers :'', 
        paper :'', 
    },
    
    getters : {
        papers: (state )=> state.papers,
        paper: (state )=> state.paper,
    },


    mutations : { 
        setpapers :(state,papers ) => (state.papers = papers), 
        setpaper :(state,paper ) => (state.paper = paper), 
    },

    actions : {

        async get_papers( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_papers')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/pastpapers?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpapers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_paper( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_paper' + id)
            return new Promise((resolve, reject) => {
            axios.post("/teacher/pastpapers/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setpaper', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}