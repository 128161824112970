<template>
    <div style="height: 70vh; display: flex !important; align-items: center; justify-content: center; background-color:inherit">
        <div class="main-loader mt-50">													
            <div class="spinner">
                <div class="bounce1"></div>
                <div class="bounce2"></div>
                <div class="bounce3"></div>
            </div>																										
        </div>
    </div>   
    </template>