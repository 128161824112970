export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        accesslogs :'', 
    },
    
    getters : {
        accesslogs: (state )=> state.accesslogs,
    },


    mutations : { 
        setaccesslogs :(state,accesslogs ) => (state.accesslogs = accesslogs), 
    },

    actions : {

        async get_user_accesslog( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_user_accesslogs')
            return new Promise((resolve, reject) => {
            axios.post("/user/accesslogs", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setaccesslogs', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



    },

    
}













