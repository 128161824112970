export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {


    actions :{

        async global_search( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_items_cats')
            return new Promise((resolve, reject) => {
            axios.post("/student/global/search", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlessons', response.data.res.lessons)
                    commit('setitems', response.data.res.libraries)
                    commit('setproducts', response.data.res.products)
                    commit('setcontests', response.data.res.contests)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    }


}