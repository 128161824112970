export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {

    state : {
        lesson :'',
        lessons :'',
        lessons_cats :'',    
    },

    getters : {
        lesson: (state )=> state.lesson,
        lessons: (state )=> state.lessons,
        lessons_cats: (state )=> state.lessons_cats,
    },

    mutations : { 
        setlesson :(state,lesson ) => (state.lesson = lesson), 
        setlessons :(state,lessons ) => (state.lessons = lessons), 
        setlessons_cats :(state,lessons_cats ) => (state.lessons_cats = lessons_cats), 
    },

    actions :{

        async get_lessons_cats( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons_cats')
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons_cats', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_lessons( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons')
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_trending_lessons( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lessons')
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons/trending?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlessons', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_lesson_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_lesson_details')
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async like_lesson( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'like_lesson')
            return new Promise((resolve, reject) => {
            axios.post("/global/likes/lesson/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async rate_lesson( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'rate_lesson')
            return new Promise((resolve, reject) => {
            axios.post("/global/ratings/lesson/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async create_lesson_review( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson_review')
            return new Promise((resolve, reject) => {
            axios.post("/global/reviews/lesson/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async create_lesson_comment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_lesson_comment')
            return new Promise((resolve, reject) => {
            axios.post("/global/comments/lesson/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },




        async lesson_enroll( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'lesson_enroll'+id)
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons/enroll/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async lesson_payment( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'lesson_payment'+id)
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons/payment/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                    commit('setlesson', response.data.res)
                    toast.success('Lesson Payment Was Made Successfully')
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        

        async create_comment( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_comment')
            return new Promise((resolve, reject) => {
            axios.post("/student/lessons/comment", data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setlesson', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    }


}