export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {

    state : {
        test :'',
        tests :'',
        testenroll:''
         
    },

    getters : {
        test: (state )=> state.test,
        tests: (state )=> state.tests,
        testenroll: (state )=> state.testenroll,
    },

    mutations : { 
        settest :(state,test ) => (state.test = test), 
        settests :(state,tests ) => (state.tests = tests), 
        settestenroll :(state,testenroll ) => (state.testenroll = testenroll),
    },

    actions :{



        async get_tests( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_tests')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settests', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_test( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_test')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async attempt_test( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'attempt_test')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/attempt/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settest', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_test_enroll_details( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_test_enroll_details')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/enrolls/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settestenroll', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async submit_question_answer( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_question_answer')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/question_answer/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settestenroll', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },  
          
        async clear_test_answers( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'clear_test_answers')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/clear_test_answers/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settestenroll', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        }, 


        
        async redo_attempted_test( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'redo_attempted_test')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/redo_attempted_test/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settestenroll', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },          
        async submit_complete_test( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'submit_complete_test')
            return new Promise((resolve, reject) => {
            axios.post("/student/tests/complete_test/" + id, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('settestenroll', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        }, 

    }


}