import store from '@/store'

const adminguard = (to, from, next) => {
  const user = store.getters.authuser;
  if(user && user.role == 'student' && localStorage.getItem('educanstudenttoken'+ user.id) != null && user.gradecurriculum_id != null /* && user.phone_verified_at != null*/ ){
      return next()
  }else if(user && user.role != 'student'){
      return next({ name: 'errorpage' })
  }else{
      return next({ name: 'login' })
  }
};


let routes = [
    { 
        path: "/",
        props: true,
        beforeEnter: adminguard,
        component: () => import("@/views/app/main.vue"),
        title:'',

          children: [

            {
              path: "/",
              name: "welcome",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/dash.vue"),
              meta: {
              title: 'Welcome to your Dashboard'
              }
            },

            {
              path: "/search_results/:data",
              name: "search_results",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/search_results.vue"),
              meta: {
              title: 'These are your search results'
              }
            },            

            {
              path: "/lessons",
              name: "lessons",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/lessons/lessons.vue"),
              meta: {
              title: 'Find Lessons for You'
              }
            },


            {
              path: "lessons/:id",
              name: "lesson_details",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/lessons/lessondetails.vue"),
              meta: {
              title: 'Lesson Details'
              }
            },

            {
              path: "lessons/study/:id",
              name: "lesson_study",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/lessons/enrolllesson.vue"),
              meta: {
              title: 'Lesson Details'
              }
            },

            {
              path: "/tests",
              name: "tests",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/tests/tests.vue"),
              meta: {
              title: 'Find Lessons for You'
              }
            },

            {
              path: "/tests/attempt/:id",
              name: "attempt_tests",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/tests/attempt_test.vue"),
              meta: {
              title: 'Find Lessons for You'
              }
            },


            {
              path: "/edushop",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/products/main.vue"),
              children: [
                {
                  path: "/edushop",
                  name: "edushop",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/products/products.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

                {
                  path: "/edushop/:id",
                  name: "edushopdetails",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/products/productdetails.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

              ]
            },

            {
              path: "/library",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/library/main.vue"),
              children: [
                {
                  path: "/library",
                  name: "library",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/library/library.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

                {
                  path: "/library/:id",
                  name: "librarydetails",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/library/librarydetails.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

              ]
            },

            {
              path: "/past-papers",
              name: "pastpapers",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/pastpapers/pastpapers.vue"),
              meta: {
              title: 'Find Lessons for You'
              }
            },

//coachings

            {
              path: "/coachings",

              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/coachings/main.vue"),
              children:[
                {
                  path: "",
                  name: "coachings",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/coachings/coachings.vue"),
                  meta: {
                  title: 'Find Lessons for You'
                  }
                },
                {
                  path: "make-request",
                  name: "make_coaching_request",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/coachings/makecoachingrequest.vue"),
                  meta: {
                  title: 'Find Lessons for You'
                  }
                },
                {
                  path: ":id/edit",
                  name: "edit_coaching_request",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/coachings/editcoaching.vue"),
                  meta: {
                  title: 'Find Lessons for You'
                  }
                },
              ]
            },

            {
              path: "/assessments",

              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/assessments/main.vue"),
              children:[
                {
                  path: "",
                  name: "assessments",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/assessments/assessments.vue"),
                  meta: {
                  title: 'Find Lessons for You'
                  }
                },
                {
                  path: "make-request",
                  name: "make_assessment_request",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/assessments/makerequest.vue"),
                  meta: {
                  title: 'Find Lessons for You'
                  }
                },
                {
                  path: ":id/edit",
                  name: "edit_assessment_request",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/assessments/editrequest.vue"),
                  meta: {
                  title: 'Find Lessons for You'
                  }
                },
              ]
            },

            {
              path: "/chatroom",
              name: "chatroom",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/chats/chats.vue"),
              meta: {
              title: 'Find Lessons for You'
              }
            },

            
            {
              path: "/shopping_cart",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/cart/main.vue"),
              children: [
                {
                  path: "cart",
                  name: "cart",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/cart/cart.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

                {
                  path: "checkout",
                  name: "checkout",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/cart/checkout.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },
              ]
            },


            {
              path: "/my-special-teacher",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/specialteachers/main.vue"),
              children: [
                {
                  path: "",
                  name: "special_teachers",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/specialteachers/teachers.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },


                {
                  path: ":id",
                  name: "teacher_details",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/specialteachers/teacher_details.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

              ]
            },


            {
              path: "/contests",

              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/contests/main.vue"),
              children:[
                {
                  path: "",
                  name: "contests",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contests/contests.vue"),
                  meta: {
                  title: store.getters.contest?.title ??'Attempt Educan Contest to Win Rewards'
                  }
                },
                {
                  path: "rules",
                  name: "contest_rules",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contests/contest_rules.vue"),
                  meta: {
                  title: store.getters.contest?.title ?? 'Contest Rules'
                  }
                },
                {
                  path: "my-reults",
                  name: "my_contest_results",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contests/my_contest_results.vue"),
                  meta: {
                  title: store.getters.contest?.title ?? 'My Contest Resets'
                  }
                },
                {
                  path: "results",
                  name: "contest_results",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contests/contest_results.vue"),
                  meta: {
                  title: store.getters.contest?.title ?? 'Contest Rules'
                  }
                },
                {
                  path: "register",
                  name: "contest_join",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contests/contest_join.vue"),
                  meta: {
                  title: store.getters.contest?.title ?? 'Contest Rules'
                  }
                },
                {
                  path: "sessions",
                  name: "contest_sessions",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/contests/contest_details.vue"),
                  meta: {
                  title: store.getters.contest?.title ?? 'Start Your Contest Sessions'
                  }
                },
                
              ]
            },




            {
              path: "/settings",
              name: "",
              props:true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/myaccount/main.vue"),
              children: [
                {
                  path: "account",
                  name: "myaccount",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/myaccount/myaccount.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

               {
                  path: "my-wallet",
                  name: "mywallet",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/myaccount/mywallet.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

                {
                  path: "membership",
                  name: "user_membership",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/myaccount/membership.vue"),
                  meta: {
                  title: 'Manage Your Membership'
                  }
                },


                {
                  path: "my-orders",
                  name: "myorders",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/myaccount/myorders.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

                {
                  path: "access-history",
                  name: "accesshistory",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/myaccount/accesshistory.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

                {
                  path: "get-help",
                  name: "gethelp",
                  props:true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/myaccount/gethelp.vue"),
                  meta: {
                  title: 'Find What You need at Edushop'
                  }
                },

              ]
            },



            {
              path: "/notifications",
              props: true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/notifications/main.vue"),
              children: [
                {
                  path: "",
                  name: "notifications",
                  props: true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/notifications/notifications.vue"),
                  meta: {
                    title: "Find Lessons for You",
                  },
                },
              ],
            },


            {
              path: "/impacts",
              props: true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/impacts/main.vue"),
              children: [
                {
                  path: "",
                  name: "impacts",
                  props: true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/impacts/impacts.vue"),
                  meta: {
                    title: "Find Lessons for You",
                  },
                },
              ],
            },



            {
              path: "/scholarships",
              props: true,
              beforeEnter: adminguard,
              component: () => import("@/views/app/scholarships/main.vue"),
              children: [
                {
                  path: "",
                  name: "scholarships",
                  props: true,
                  beforeEnter: adminguard,
                  component: () => import("@/views/app/scholarships/scholarships.vue"),
                  meta: {
                    title: "Find Lessons for You",
                  },
                },
              ],
            },

        ]
    },
    {
      path: "/contest/sessions/test/:id",
      name: "contest_session_test",
      props:true,
      beforeEnter: adminguard,
      component: () => import("@/views/app/contests/session_test.vue"),
      meta: {
      title: store.getters.contest?.title ?? 'Attempt Your Test'
      }
    },

    
]
export default routes;