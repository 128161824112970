export const namespaced = true 
export default {
    state : {
        error :'',
        errors :[],
        apierror:'',
        msg :'',
        loading:'',
        pageloading:'',
        logintypetype:'',
        uploadPercentage:0,
        nav_menu:'',
        contest_test_time_inseconds:0,
    },
    
    getters : {
        error: (state )=> state.error,
        errors: (state )=> state.errors,
        apierror: (state )=> state.apierror,
        msg: (state )=> state.msg,
        loading: (state )=> state.loading,
        pageloading: (state )=> state.pageloading,
        uploadPercentage: (state )=> state.uploadPercentage,
        nav_menu: (state )=> state.nav_menu,
        contest_test_time_inseconds: (state )=> state.contest_test_time_inseconds,
    },

    

    mutations : { 
        setloader :(state,loading ) => (state.loading = loading), 
        setpageloading :(state,pageloading ) => (state.pageloading = pageloading), 
        seterror :(state,error ) => (state.error = error), 
        seterrors :(state,errors ) => (state.errors = errors), 
        setmsg :(state,msg ) => (state.msg = msg), 
        setapierror :(state,apierror ) => (state.apierror = apierror),
        setuploadPercentage :(state,uploadPercentage ) => (state.uploadPercentage = uploadPercentage),
        setnav_menu :(state,nav_menu ) => (state.nav_menu = nav_menu),
        setcontest_test_time_inseconds :(state,contest_test_time_inseconds ) => (state.contest_test_time_inseconds = contest_test_time_inseconds),
    },


    actions : {

        async clearutils( { commit }) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', '')
            commit('setapierror', '')
            commit('seterrors', '')
            commit('setpageloading', '')
        },


        async update_contest_test_time( { commit }, data) {
            commit('setcontest_test_time_inseconds', data.secs)
        },


        async changenav_menu( { commit }, value) {
            commit('setnav_menu', value)
        },





    },

    
}