export const namespaced = true 
import { useToast } from 'vue-toastification'
import router from '@/router'
const toast = useToast()

export default {
    state : {
        carts :'', 
        cart :'', 
    },
    
    getters : {
        carts: (state )=> state.carts,
        cart: (state )=> state.cart,
    },


    mutations : { 
        setcarts :(state,carts ) => (state.carts = carts), 
        setcart: (state, cart) => (state.cart = cart),
        set_item: (state, item) => {
            state.cart.items.splice(0, 0, item)
        },
    },

    actions : {

        async get_carts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_carts')
            return new Promise((resolve, reject) => {
            axios.post("/global/carts?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcarts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_cart( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_cart')
            return new Promise((resolve, reject) => {
            axios.post("/global/carts/cart", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcart', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                            if(error.response.data.message == 'Unauthenticated.'){
                                localStorage.clear()
                                commit('setauthuser', '')
                                this.$router.push({name:'login'})
                            }
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async add_to_cart( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'add_to_carts')
            return new Promise((resolve, reject) => {
            axios.post("/global/carts/add_tocart", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcarts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        push_item( { commit }, item) {
            commit('set_item',item)
        },


        async update_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/carts/update_item/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                    toast.success('Item Updated Successfully')
                    commit('setcart', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async delete_item( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_item' + id)
            return new Promise((resolve, reject) => {
            axios.post("/global/carts/delete_item/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setcart', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}