import { createApp } from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import './axios'
import moment from 'moment'
import Toast from "vue-toastification"
import "vue-toastification/dist/index.css"

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap/dist/js/bootstrap.js"
import "@/assets/app_style.css"
import "@/assets/custom_style.css"
import "@/assets/app_style_dark.css"
import "@/assets/app_script.js"
//css

import "@/assets/js/vertical-responsive-menu.min.js"
// import "@/assets/js/jquery-3.3.1.min.js"
import "@/assets/vendor/bootstrap/js/bootstrap.bundle.min.js"
// import "@/assets/vendor/OwlCarousel/owl.carousel.js"
import "@/assets/vendor/semantic/semantic.min.js"
// import "@/assets/js/custom.js"
import "@/assets/js/night-mode.js"

import "@/assets/css/style.css"
import "@/assets/css/responsive.css"
import "@/assets/css/night-mode.css"
import "@/assets/css/student_dashboard.css"
import "@/assets/css/student_responsive.css"
import "@/assets/vendor/OwlCarousel/assets/owl.carousel.css"
import "@/assets/vendor/OwlCarousel/assets/owl.theme.default.min.css"
import "@/assets/vendor/bootstrap/css/bootstrap.min.css"

import { QuillEditor } from '@vueup/vue-quill'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import '@vueup/vue-quill/dist/vue-quill.bubble.css';
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'


//video
import Vue3VideoPlayer from '@cloudgeek/vue3-video-player'
import '@cloudgeek/vue3-video-player/dist/vue3-video-player.css'

import 'viewerjs/dist/viewer.css'
import VueViewer from 'v-viewer'
import vue3GoogleLogin from 'vue3-google-login'
//components
import loadingBtn from '@/views/comps/loadingbtn'
import paymentmodel from '@/views/comps/paymentmodel.vue';
import dataLoader from '@/views/comps/dataLoader.vue';
import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from '@fortawesome/free-solid-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons';
import { far } from '@fortawesome/free-regular-svg-icons';
import { dom } from "@fortawesome/fontawesome-svg-core";
library.add(far);
library.add(fab);
library.add(fas);
dom.watch();


import Echo from 'laravel-echo'
window.Pusher = require('pusher-js')

window.Echo = new Echo({
    broadcaster: 'pusher',
    key: '7dc85893a1b1f0775552',
    cluster: 'mt1',
    encrypted: true
})


let CLIENTID = "381258396702-94itbkqiqnv7omn5of7u47cpqf14251f.apps.googleusercontent.com"
let CLIENTSECRET = "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"

const app = createApp(App)
app.config.globalProperties.$filters = {
    dateToday() {
        var today = new Date();
        return moment(today).format("DD/MM/YYYY")
    },

    timeAgo(date) {
    return moment(date).fromNow()
    },

    dateonly(date) {
        return moment(date).format("DD/MM/YYYY")
    },

    datetime(date){
        return moment(date).format("YYYY-MM-DD HH:mm:ss")
    },

    futuredatetime(date){
        return moment(date).format("dddd MMMM YYYY [at] h:mma")
    },

    textdatetime(date){
        return moment(date).format('llll')
    },
    textdateday(date){
        return moment(date).format('MMMM, Do')
    },
    timeonly(date){
        return moment(date).format("HH:mm:ss")
    },
    convertTimeOnly(date) {
        return moment(date).format('hh:mm A');
    },

    remove_dash(value){
        if (!value) return ''
        return value.replace(/_/g, ' ') 
    },

    currency(value) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
    },
}

app.use(vue3GoogleLogin, {
    clientId: CLIENTID
})


app.use(Vue3VideoPlayer, { lang: 'en' })
    .use(VueViewer).component('loadingBtn', loadingBtn)
    .component('paymentmodel', paymentmodel)
    .component('dataLoader', dataLoader)
    .component("font-awesome-icon", FontAwesomeIcon).component('QuillEditor', QuillEditor).component('carousel', Carousel).component('slide', Slide).component('navigation', Navigation).component('pagination', Pagination)
    .use(store).use(router).use(Toast)
app.mount('#app')
