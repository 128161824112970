<template>
<button :class="['button', className]" type="button" v-if="loadingvalue != '' && loadingvalue === loading">
	<span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
  {{ loadingTxt }}
</button>

<button v-else :class="['button', className]" >{{  btnText }}
</button>

  
</template>
<script>
import { mapGetters } from 'vuex'
export default {

    props: {
        
        loadingvalue: {
            type: [String, Boolean, Number],
            default: ''
        },
        loadingTxt: {
            type: String,
            default: 'Loading...'
        },
        className: {
            type: String,
            default: 'btn-primary'
        },
        btnText: {
            type: String,
            default: 'Submit'
        }
    },

    computed:{
        ...mapGetters(['loading'])
    },

    methods: {
        handleClick() {
            if(!this.loading) {
            this.onClick()
            }
        }
    },
}
</script>