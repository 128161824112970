export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        grade :'',
        grades : '',
        topics: '',
        advert :'',
        adverts : '',
        delivery_areas: '',  
        subject :'',
        subjects: '',
        level :'',
        levels : '',
    },
    
    getters : {
        grade: (state )=> state.grade,
        grades: (state )=> state.grades,
        topics: (state )=> state.topics,
        advert: (state )=> state.advert,
        adverts: (state) => state.adverts,
        delivery_areas: (state) => state.delivery_areas,
        subject: (state )=> state.subject,
        subjects: (state) => state.subjects,
        level: (state )=> state.level,
        levels: (state) => state.levels,
    },


    mutations : { 
        setgrade :(state,grade ) => (state.grade = grade), 
        setgrades :(state,grades ) => (state.grades = grades),
        settopics :(state,topics ) => (state.topics = topics),
        setadvert :(state,advert ) => (state.advert = advert), 
        setadverts :(state,adverts ) => (state.adverts = adverts), 
        setdelivery_areas: (state, delivery_areas) => (state.delivery_areas = delivery_areas), 
        setsubject :(state,subject ) => (state.subject = subject), 
        setsubjects: (state, subjects) => (state.subjects = subjects),
        setlevel :(state,level ) => (state.level = level), 
        setlevels :(state,levels ) => (state.levels = levels),
    },

    actions : {

        async get_grades( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grades')
            return new Promise((resolve, reject) => {
            axios.post("/student/global/grades?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setgrades', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_subjects( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_subjects')
            return new Promise((resolve, reject) => {
            axios.post("/student/global/subjects?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setsubjects', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_levels( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_levels')
            return new Promise((resolve, reject) => {
            axios.post("/student/global/levels?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setlevels', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_grade_subject_topics( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grade_subject_topics')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/global/topics?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('settopics', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_adverts( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_grades')
            return new Promise((resolve, reject) => {
            axios.post("/student/global/adverts?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setadverts', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },



        async get_delivery_areas( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_delivery_areas')
            return new Promise((resolve, reject) => {
            axios.post("/student/global/delivery_areas?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data){
                commit('setdelivery_areas', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

    },

    
}













