import { createStore } from 'vuex'
import createPersistedState from "vuex-persistedstate";
import SecureLs from 'secure-ls'
const secure = new SecureLs({isCompression:false});
import auth  from './modules/auth'
import utils  from './modules/utils'
import wallet  from './modules/wallet'
import authuser  from './modules/authuser'
import essentials  from './modules/essentials'
import teacher_resources  from './modules/teacher_resources'
import products  from './modules/products'
import lessons  from './modules/lessons'
import tests  from './modules/tests'
import assessments  from './modules/assessments'
import coachings  from './modules/coachings'
import past_papers  from './modules/past_papers'
import library  from './modules/library'
import chat  from './modules/chat'
import cart  from './modules/cart'
import special_teachers from './modules/special_teachers'
import contests from './modules/contests'
import orders from './modules/orders'
import notifications from './modules/notifications'
import feedback from './modules/feedback'
import global from './modules/global'
import impacts from "./modules/impacts";
import scholarships from "./modules/scholarships";

export default createStore({
  modules: {
    auth, 
    utils,
    authuser,
    essentials,
    teacher_resources,
    products,
    lessons,
    tests,
    wallet,
    assessments,
    coachings,
    past_papers,
    library,
    chat,
    cart,
    special_teachers,
    contests,
    orders,
    notifications,
    feedback,
    global,
    impacts,
    scholarships,
  },
    
  plugins: [createPersistedState(
    {
      key : 'educanug.com',
      paths: ['auth','utils','essentials'],
      storage:{
         getItem : (key) => secure.get(key),
         setItem: (key, value) => secure.set(key, value),
         removeItem: (key) => secure.remove(key)
      }
    }
  )],
  
})
