export const namespaced = true
import store from '@/store'
import { useToast } from 'vue-toastification'
const toast = useToast()
export default {

    state : {
        teacher_resource :'',
        teacher_resources :'',
        teacher_resources_cats :'',    
    },

    getters : {
        teacher_resource: (state )=> state.teacher_resource,
        teacher_resources: (state )=> state.teacher_resources,
        teacher_resources_cats: (state )=> state.teacher_resources_cats,
    },

    mutations : { 
        setteacher_resource :(state,teacher_resource ) => (state.teacher_resource = teacher_resource), 
        setteacher_resources :(state,teacher_resources ) => (state.teacher_resources = teacher_resources), 
        setteacher_resources_cats :(state,teacher_resources_cats ) => (state.teacher_resources_cats = teacher_resources_cats), 
    },

    actions :{

        async get_teacher_resources_cats( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teacher_resources_cats')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/tr_resource_categories?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setteacher_resources_cats', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async get_teacher_resources( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teacher_resources')
            return new Promise((resolve, reject) => {
            axios.post("/teacher/resources?page=" + page, data)
            .then(response => {	
                commit('setloader', false)
                if(response.data.res){
                commit('setteacher_resources', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
        

    }


}