export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        teachers :'', 
        teacher :'', 
    },
    
    getters : {
        teachers: (state )=> state.teachers,
        teacher: (state )=> state.teacher,
    },


    mutations : { 
        setteachers :(state,teachers ) => (state.teachers = teachers), 
        setteacher :(state,teacher ) => (state.teacher = teacher), 
    },

    actions : {

        async create_teacher( { commit }, data) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'create_teacher')
            return new Promise((resolve, reject) => {
            axios.post("/student/special_teachers/create", data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteachers', response.data.res)
                toast.success("Teacher Added Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_teachers( { commit }, {data, page}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teachers')
            return new Promise((resolve, reject) => {
            axios.post("/student/special_teachers?page=" + page, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteachers', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async get_teacher( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_teacher' + id)
            return new Promise((resolve, reject) => {
            axios.post("/student/special_teachers/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteacher', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async update_teacher( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_teacher' + id)
            return new Promise((resolve, reject) => {
            axios.post("/student/special_teachers/update/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteachers', response.data.res)
                toast.success("Teacher Updated Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async update_tr_image( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_tr_image' + id)
            return new Promise((resolve, reject) => {
            axios.post("/student/special_teachers/image/" + id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteacher', response.data.res)
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },

        async delete_teacher( { commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'delete_teacher' + id)
            return new Promise((resolve, reject) => {
            axios.post("/student/special_teachers/delete/"+ id, data)
            .then(response => {	

                commit('setloader', false)
                if(response.data){
                commit('setteachers', response.data.res)
                toast.success("Teacher Deleted Successfully")
                }
                resolve(response)
                
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },
    }
}