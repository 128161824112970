export const namespaced = true 
import { useToast } from 'vue-toastification'
const toast = useToast()

export default {
    state : {
        contests :'', 
        contest: '',
        sessiontest: '',
        contestresults: '',
    },
    
    getters : {
        contests: (state )=> state.contests,
        contest: (state) => state.contest,
        sessiontest: (state) => state.sessiontest,
        contestresults: (state) => state.contestresults,
    },


    mutations : { 
        setcontests :(state,contests ) => (state.contests = contests), 
        setcontest: (state, contest) => (state.contest = contest),
        setsessiontest: (state, sessiontest) => (state.sessiontest = sessiontest),
        setcontestresults: (state, contestresults) => (state.contestresults = contestresults),
    },

    actions : {

        async get_contest( { commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_contest')
            return new Promise((resolve, reject) => {
            axios.post("/student/contest", data)
            .then(response => {	
                if(response.data){
                commit('setcontest', response.data.res)
                }
                resolve(response)
                commit('setloader', false)
            })
            .catch(error=> {
                commit('setloader', false)
                if (error.response) {
                    if(error.response.data){
                        if(error.response.data.msg){
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        }else if(error.response.data.message){
                            commit('seterrors', error.response.data.message)
                        }
                        
                    }
                }
                reject(error)
            });
            });	       
        },


        async join_contest({ commit }, {data}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'join_contest')
            return new Promise((resolve, reject) => {
                axios.post(`/student/contest/register`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setcontest', response.data.res)
                        if (response.data.res) {
                            toast.success("Joining request sent successfully")
                        } else {
                            toast.warning(response.data.msg)
                        }
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async start_competition_category_session({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'start_competition_category_session')
            return new Promise((resolve, reject) => {
                axios.post(`/student/contest/session/start/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsessiontest', response.data.res)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        async get_contest_session_test({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'get_contest_session_test')
            return new Promise((resolve, reject) => {
                axios.post(`/student/contest/session/test/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsessiontest', response.data.res)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

       async update_user_test_question({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'update_user_test_question')
            return new Promise((resolve, reject) => {
                axios.post(`/student/contest/session/test/update/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsessiontest', response.data.res)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

       async complete_contest_test_session({ commit }, {data, id}) {
            commit('seterror', '')
            commit('setmsg', '')
            commit('setloader', 'complete_contest_test_session')
            return new Promise((resolve, reject) => {
                axios.post(`/student/contest/session/test/complete/${id}`, data)
                .then(response => {
                    commit('setloader', false)
                    if (response.data) {
                        commit('setsessiontest', response.data.res)
                    }
                    resolve(response)
                })
                .catch(error => {
                    commit('setloader', false)
                    if (error.response) {
                        if (error.response.data) {
                            if (error.response.data.msg) {
                                commit('seterror', error.response.data.msg)
                                toast.error(error.response.data.msg)
                            } else if (error.response.data.message) {
                                commit('seterrors', error.response.data.message)
                            }
                        }
                    }
                    reject(error)
                });
            });
        },

        
    async get_my_contest_results({ commit }, {data}) {
        commit('seterror', '')
        commit('setmsg', '')
        commit('setloader', 'get_my_contest_results')
        return new Promise((resolve, reject) => {
            axios.post(`/student/contest/myresults`, data)
            .then(response => {
                commit('setloader', false)
                if (response.data) {
                    commit('setcontestresults', response.data.res)
                }
                resolve(response)
            })
            .catch(error => {
                commit('setloader', false)
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        } else if (error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                        }
                    }
                }
                reject(error)
            });
        });
    },

    async get_contest_standings({ commit }, {data}) {
        commit('seterror', '')
        commit('setmsg', '')
        commit('setloader', 'get_contest_standings')
        return new Promise((resolve, reject) => {
            axios.post(`/student/contest/standings`, data)
            .then(response => {
                commit('setloader', false)
                if (response.data) {
                    commit('setcontestresults', response.data.res)
                }
                resolve(response)
            })
            .catch(error => {
                commit('setloader', false)
                if (error.response) {
                    if (error.response.data) {
                        if (error.response.data.msg) {
                            commit('seterror', error.response.data.msg)
                            toast.error(error.response.data.msg)
                        } else if (error.response.data.message) {
                            commit('seterrors', error.response.data.message)
                        }
                    }
                }
                reject(error)
            });
        });
    },
}
}